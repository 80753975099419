import { NextRequest } from 'next/server';

import { delay } from '@/lib/promises';

import { SECOND_IN_MS } from './date-utils';
import { captureException, captureMessage } from './sentry';

const DEFAULT_RETRY_DELAY = 2 * SECOND_IN_MS;
export const DEFAULT_NB_RETRIES = 3;

export enum HttpMethods {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Options = 'OPTIONS',
  Delete = 'DELETE',
}

export enum HttpErrorNames {
  MethodNotAllowed = 'MethodNotAllowed',
}

export type OnErrorResParams = {
  status: number;
  body: any;
};

export async function tryFetchToJson<T>(params: {
  fetchParams: { input: RequestInfo | URL; init?: RequestInit | undefined };
  onSuccess?: (response: T) => any;
  onFunctionalError?: (res?: OnErrorResParams) => any;
  onTechnicalError?: (err: any) => any;
  withoutResponse?: boolean;
  skipFetchError?: boolean;
  withAuthToken?: boolean;
  nbRetries?: number;
  retryDelay?: number;
}): Promise<T> {
  const {
    fetchParams,
    onSuccess,
    onFunctionalError,
    onTechnicalError,
    withoutResponse,
    skipFetchError,
    nbRetries = 0,
    retryDelay = DEFAULT_RETRY_DELAY,
  } = params;

  const fetchParamsInit = {
    ...fetchParams.init,
    headers: {
      ...fetchParams.init?.headers,
      ...(params.withAuthToken && {
        Authorization: `Bearer ${await getUserToken()}`,
      }),
    },
  };

  const retry = async () => {
    await delay(retryDelay);
    return tryFetchToJson({
      ...params,
      nbRetries: nbRetries - 1,
    });
  };

  try {
    const res = await fetch(fetchParams.input, fetchParamsInit);

    if (res.status >= 400) {
      captureMessage(
        `Failed to fetch ${fetchParams.input} with status code ${res.status}`,
        res.status < 500 ? 'warning' : 'error'
      );

      if (nbRetries > 0) {
        return retry();
      }

      try {
        const body = await res.json();
        return onFunctionalError?.({ status: res.status, body });
      } catch {
        return onFunctionalError?.({ status: res.status, body: undefined });
      }
    }

    const response =
      res.status === 204 || withoutResponse ? null : ((await res.json()) as T);

    if (onSuccess == null) {
      return response as T;
    }

    return onSuccess(response as T);
  } catch (err: any) {
    if (!skipFetchError) {
      captureException(
        `Failed to fetch ${fetchParams.input}: ${err?.toString()}`,
        err
      );
    }

    if (nbRetries > 0) {
      return retry();
    }

    return onTechnicalError?.(err);
  }
}

export function getClientIp(req: NextRequest): string {
  let ip = req.headers.get('x-real-ip') as string;

  const forwardedFor = req.headers.get('x-forwarded-for') as string;
  if (!ip && forwardedFor) {
    ip = forwardedFor?.split(',').at(0) ?? 'Unknown';
  }

  return ip;
}

export const getUserToken = async () => {
  const AuthHelpers = await import('@/lib/authentication/helpers');
  const { isValid } = await AuthHelpers.checkUserToken();
  const { token } = await AuthHelpers.getUserToken();

  let userToken = token;
  if (!isValid) {
    try {
      const { message } = await AuthHelpers.refreshUserToken();
      userToken = message.refreshToken.token;
    } catch (error) {
      AuthHelpers.logout(); // making sure we remove auth data from local storage
    }
  }

  return userToken;
};
