import {
  Duration,
  Interval,
  add as dateFnsAdd,
  addBusinessDays as dateFnsAddBusinessDays,
  addDays as dateFnsAddDays,
  addHours as dateFnsAddHours,
  addMinutes as dateFnsAddMinutes,
  addMonths as dateFnsAddMonths,
  differenceInDays as dateFnsDifferenceInDays,
  differenceInMinutes as dateFnsDifferenceInMinutes,
  eachDayOfInterval as dateFnsEachDayOfInterval,
  endOfMonth as dateFnsEndOfMonth,
  format as dateFnsFormat,
  getQuarter as dateFnsGetQuarter,
  isAfter as dateFnsIsAfter,
  isEqual as dateFnsIsEqual,
  isFuture as dateFnsIsFuture,
  isPast as dateFnsIsPast,
  isSameDay as dateFnsIsSameDay,
  isSameHour as dateFnsIsSameHour,
  isSameISOWeek as dateFnsIsSameISOWeek,
  isSameMonth as dateFnsIsSameMonth,
  isWithinInterval as dateFnsIsWithinInterval,
  parse as dateFnsParse,
  setHours as dateFnsSetHours,
  setMinutes as dateFnsSetMinutes,
  startOfMonth as dateFnsStartOfMonth,
  subHours as dateFnsSubHours,
  differenceInHours as dateFnsdifferenceInHours,
  setDefaultOptions,
} from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import { enUS as en, fr, nl } from 'date-fns/locale';

import { DateRange, PartialDateRange } from '@/models/DateRange';

const LOCALES = [en, fr, nl];
export const DATE_LOCALE_EN = en;
export const SECOND_IN_MS = 1000;
export const MINUTE_IN_SECONDES = 60;
export const HOUR_IN_MINUTES = 60;
export const HOUR_IN_MS = HOUR_IN_MINUTES * MINUTE_IN_SECONDES * SECOND_IN_MS;
export const DAY_IN_MS = 24 * HOUR_IN_MS;

export function getFirstDayOfNextMonth(startDate: Date): Date {
  const nextMonth = addMonths(startDate, 1);
  nextMonth.setDate(1);

  return nextMonth;
}

export function addMonths(startDate: Date, amount: number): Date {
  return dateFnsAddMonths(startDate, amount);
}

export function isWithinInterval(date: Date, amount: Interval): boolean {
  return dateFnsIsWithinInterval(date, amount);
}

export function format(
  date: Date,
  format: string,
  options?: {
    locale?: Locale;
  }
): string {
  return dateFnsFormat(date, format, options);
}

export function getQuarter(date: Date) {
  return dateFnsGetQuarter(date);
}

export function differenceInDays(dateLeft: Date, dateRight: Date) {
  return dateFnsDifferenceInDays(dateLeft, dateRight);
}

export function addDays(date: Date, amount: number) {
  return dateFnsAddDays(date, amount);
}

export function endOfMonth(date: Date) {
  return dateFnsEndOfMonth(date);
}

export function addHours(date: Date, hours: number) {
  return dateFnsAddHours(date, hours);
}

export function setLocale(locale: string) {
  setDefaultOptions({ locale: LOCALES.find((l) => l.code === locale) });
}

export function differenceInHours(dateLeft: Date, dateRight: Date) {
  return dateFnsdifferenceInHours(dateLeft, dateRight);
}

export function differenceInMinutes(dateLeft: Date, dateRight: Date) {
  return dateFnsDifferenceInMinutes(dateLeft, dateRight);
}

export function eachDayOfInterval(start: Date, end: Date) {
  return dateFnsEachDayOfInterval({ start, end });
}

export function addMinutes(date: Date, amount: number) {
  return dateFnsAddMinutes(date, amount);
}

export function add(date: Date, duration: Duration) {
  return dateFnsAdd(date, duration);
}

export function addBusinessDays(date: Date, amount: number) {
  return dateFnsAddBusinessDays(date, amount);
}

export function parse(time: string, format: string, date: Date) {
  return dateFnsParse(time, format, date);
}

export function isSameHour(dateLeft: Date, dateRight: Date) {
  return dateFnsIsSameHour(dateLeft, dateRight);
}

export function isSameMonth(dateLeft: Date, dateRight: Date) {
  return dateFnsIsSameMonth(dateLeft, dateRight);
}

export function isEqual(dateLeft: Date, dateRight: Date) {
  return dateFnsIsEqual(dateLeft, dateRight);
}
export function isAfter(dateLeft: Date, dateRight: Date) {
  return dateFnsIsAfter(dateLeft, dateRight);
}

export function isSameDay(dateLeft: Date, dateRight: Date) {
  return dateFnsIsSameDay(dateLeft, dateRight);
}

export function setHours(date: Date, hours: number) {
  return dateFnsSetHours(date, hours);
}

export function setMinutes(date: Date, minutes: number) {
  return dateFnsSetMinutes(date, minutes);
}

export function subHours(date: Date, amount: number) {
  return dateFnsSubHours(date, amount);
}

export function getFormattedTime(time: string): string {
  const today = new Date();
  today.setHours(...(time.split(':').map(Number) as [number, number]));
  return format(today, 'p');
}

export function getNbThirtyMinuteSlots(startDate: Date, endDate: Date): number {
  return differenceInHours(endDate, startDate) * 2;
}

export function getThirtyMinuteSlots(startDate: Date, endDate: Date): Date[] {
  const dateHourDifference = getNbThirtyMinuteSlots(startDate, endDate);
  return Array.from({ length: dateHourDifference + 1 }, (_, index) => {
    return addMinutes(startDate, index * 30);
  });
}

export function getFormattedDateWithTime(date: Date, time: string) {
  const today = new Date();
  today.setHours(...(time.split(':').map(Number) as [number, number]));
  today.setSeconds(0);
  return format(date, 'yyyy-MM-dd') + 'T' + format(today, 'HH:mm:ss');
}

export function isPast(date: Date): boolean {
  return dateFnsIsPast(date);
}

export function isFuture(date: Date): boolean {
  return dateFnsIsFuture(date);
}

// Used only for the time
export function getDateFromTime(hour: number, minute: number) {
  return new Date(2000, 1, 1, hour, minute, 0);
}

export function createDateFromLocalDate(localDate: string) {
  return new Date(`${localDate}Z`);
}

export function getISOTime(date: Date) {
  return getTimeFromLocalDate(date.toISOString());
}

export function getTimeFromLocalDate(localDate: string) {
  return localDate.split('T')[1].slice(0, 5);
}

export function isValidDate(date: string) {
  return new Date(date).toString() !== 'Invalid Date';
}

export function isPastDay(date: Date) {
  const today = new Date(new Date().toISOString().split('T')[0]);

  return date.getTime() < today.getTime();
}

export function createLocalDay(local: string) {
  const day = local.split('T')[0];
  return new Date(day.replace(/-/g, '/'));
}

export function generateMonthDayDates(month: Date, margin = 0) {
  const nextMonth = getNextMonth(month);

  const start = addDays(month, -margin);
  const end = addDays(nextMonth, margin);

  return generateDates(start, end);
}

export function getNextMonth(month: Date) {
  const nextMonth = new Date(month);

  nextMonth.setMonth(nextMonth.getMonth() + 1);

  return nextMonth;
}

export function generateDates(start: Date, end: Date) {
  const dates: Date[] = [];

  const date = new Date(start);
  while (date < end) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

export function getMonthDate(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getDateFromLocal(localDate: string | Date, timeZone: string) {
  return zonedTimeToUtc(localDate, timeZone);
}

export function getDateFormatInTimeZone(
  date: Date,
  timeZone: string,
  format: string
) {
  return formatInTimeZone(date, timeZone, format);
}

export function getTimeInTimeZone(date: Date, timeZone: string) {
  return getDateFormatInTimeZone(date, timeZone, 'HH:mm');
}

export function getNbHours(start: Date, end: Date): number {
  return (end.getTime() - start.getTime()) / HOUR_IN_MS;
}

export function formatTime(time: string) {
  return format(new Date(`2000-01-01T${time}:00`), 'h:mm a');
}

export function formatTimeLowercase(time: string) {
  return formatTime(time).toLowerCase();
}

export function getTomorrowDay() {
  return getNextDay(formatToDay(new Date()));
}

export function formatToISODay(date: Date | string) {
  const dateStr = typeof date === 'string' ? date : date.toISOString();
  return dateStr.split('T')[0];
}

export function getLocalDay(date: Date) {
  const isoDay = formatToISODay(date);
  return isoDay.replaceAll(/-/g, '/');
}

export function getLocalDateFromDay(day: string) {
  return new Date(day.replaceAll(/-/g, '/'));
}

export function formatToDay(date: Date) {
  return format(date, 'yyyy-MM-dd');
}

export function getNbDays({ from, to }: DateRange): number {
  const timeGap = to.getTime() - from.getTime() + 1;
  return Math.ceil(timeGap / DAY_IN_MS);
}

export function isDateRange(
  date: PartialDateRange | undefined
): date is DateRange {
  return date?.from instanceof Date && date?.to instanceof Date;
}

export function getNextDay(day: string) {
  const date = new Date(day);

  return formatToISODay(addDays(date, 1));
}

export function getAbbreviatedDayName(day: string) {
  const date = parse(day, 'yyyy-MM-dd', new Date());
  return format(date, 'EEE', { locale: DATE_LOCALE_EN }).toLowerCase();
}

export function eachDayOfDateRange({ from, to }: DateRange) {
  return dateFnsEachDayOfInterval({ start: from, end: to });
}

export function isWeekEnd(day: string) {
  const dayName = getAbbreviatedDayName(day);

  return ['sat', 'sun'].includes(dayName);
}

export function isSameWeek(day1: string, day2: string) {
  return dateFnsIsSameISOWeek(new Date(day1), new Date(day2));
}

export function startOfMonth(date: Date) {
  return dateFnsStartOfMonth(date);
}
